<template>
  <div class="container faq">
    <div class="col-12">
      <h6 class="mb-5">{{ $t("faqPage.faqAbout") }}</h6>
      <ol>
        <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-3">
              {{ $t("faqPage.whatFlinktax") }}
            </div>
            {{ $t("faqPage.flinktaxModernPlatform") }}
          </div>
          <div>
            <div
              class="font-weight-bold mb-3 read-more text-blue"
              @click="() => changeShow(1)"
            >
              {{ $t("faqPage.readMore") }}
            </div>
            <div v-if="isShow.includes(1)">
              {{ $t("faqPage.whatFlinktaxText") }}
            </div>
          </div>
        </li>
        <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-3">
              {{ $t("faqPage.flinktaxSafe") }}
            </div>
            {{ $t("faqPage.safetyFlinktax") }}
          </div>
          <div>
            <div class="mb-3">
              <div
                class="font-weight-bold read-more text-blue"
                @click="() => changeShow(2)"
              >
                {{ $t("faqPage.readMore") }}
              </div>
            </div>
            <div v-if="isShow.includes(2)">
              <p class="mb-3">
                {{ $t("faqPage.safetyFlinktaxOneText") }}
                {{ $t("faqPage.safetyFlinktaxTwoText") }}
              </p>
              <p class="mb-3">
                {{ $t("faqPage.safetyFlinktaxThreeText") }}
              </p>
              <p class="mb-3">
                {{ $t("faqPage.safetyFlinktaxFourText") }}
              </p>
              <p class="mb-3">
                {{ $t("faqPage.safetyFlinktaxFiveText") }}
              </p>
            </div>
          </div>
        </li>
        <li>
          <p class="mb-2">
            <span class="font-weight-bold">{{
              $t("faqPage.flinktaxSupport")
            }}</span>
          </p>
          <p class="mb-2">
            {{ $t("faqPage.uniqueTaxChallenges") }}
          </p>
          <div>
            <div class="mb-3">
              <span
                class="font-weight-bold read-more text-blue"
                @click="() => changeShow(3)"
                >{{ $t("faqPage.readMore") }}</span
              >
            </div>
            <div v-if="isShow.includes(3)">
              <p class="mb-3">
                {{ $t("faqPage.flinktaxSupportOneText") }}
                {{ $t("faqPage.flinktaxSupportTwoText") }}
              </p>
              <p class="mb-3">
                {{ $t("faqPage.flinktaxSupportThreeText") }}
              </p>
            </div>
          </div>
        </li>
        <li>
          <p class="mb-2">
            <span class="font-weight-bold">{{
              $t("faqPage.foreignIncome")
            }}</span>
          </p>
          <p class="mb-2">
            {{ $t("faqPage.progressionProviso") }}
          </p>
          <div class="text-blue mb-3">
            <span
              class="font-weight-bold read-more"
              @click="() => changeShow(4)"
              >{{ $t("faqPage.readMore") }}</span
            >
          </div>
          <div v-if="isShow.includes(4)">
            {{ $t("faqPage.progressionProvisoText") }}
          </div>
        </li>
        <li>
          <p class="mb-2">
            <span class="font-weight-bold">{{
              $t("faqPage.relocationExpenses")
            }}</span>
          </p>
          <p class="mb-2">
            {{ $t("faqPage.relocationExpensesDesc") }}
          </p>
          <div class="text-blue mb-3">
            <span
              class="font-weight-bold read-more"
              @click="() => changeShow(5)"
              >{{ $t("faqPage.readMore") }}</span
            >
          </div>
          <div v-if="isShow.includes(5)">
            {{ $t("faqPage.relocationExpensesText") }}
          </div>
        </li>
        <li>
          <p class="mb-2">
            <span class="font-weight-bold">{{
              $t("faqPage.germanTaxReturn")
            }}</span>
          </p>
          <p class="mb-2">
            {{ $t("faqPage.germanTaxReturnDesc") }}
          </p>
          <div class="text-blue mb-3">
            <span
              class="font-weight-bold read-more"
              @click="() => changeShow(6)"
              >{{ $t("faqPage.readMore") }}</span
            >
          </div>
          <div v-if="isShow.includes(6)">
            {{ $t("faqPage.germanTaxReturnText") }}
          </div>
        </li>
        <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-2">
              {{ $t("faqPage.howFlinktaxWorks") }}
            </div>
            {{ $t("faqPage.howFlinktaxWorksDesc") }}
          </div>
          <div>
            <div class="mb-3">
              <span
                class="font-weight-bold read-more text-blue"
                @click="() => changeShow(7)"
                >{{ $t("faqPage.readMore") }}</span
              >
            </div>
            <div v-if="isShow.includes(7)">
              <div class="mb-3">
                {{ $t("faqPage.howFlinktaxWorksText") }}
              </div>
              <ol class="mb-5">
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.registration")
                  }}</span>
                  {{ $t("faqPage.registrationText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.profiling")
                  }}</span>
                  {{ $t("faqPage.profilingText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.answerQuestion")
                  }}</span>
                  {{ $t("faqPage.answerQuestionText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.documentUpload")
                  }}</span
                  >{{ $t("faqPage.documentUploadText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.professionalReview")
                  }}</span>
                  {{ $t("faqPage.professionalReviewText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.editingSubmission")
                  }}</span>
                  {{ $t("faqPage.editingSubmissionText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.refund")
                  }}</span>
                  {{ $t("faqPage.refundText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.support")
                  }}</span
                  >{{ $t("faqPage.supportText") }}
                </li>
              </ol>
              <p>
                {{ $t("faqPage.flinktaxOffers") }}
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-2">
              {{ $t("faqPage.helpQuestion") }}
            </div>
            {{ $t("faqPage.helpQuestionText") }}
          </div>
          <div>
            <div class="mb-3">
              <span
                class="font-weight-bold read-more text-blue"
                @click="() => changeShow(8)"
                >{{ $t("faqPage.readMore") }}</span
              >
            </div>
            <div v-if="isShow.includes(8)">
              <div class="mb-3">
                {{ $t("faqPage.customerSupportTeam") }}
              </div>
              <ol class="mb-5">
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.directChatSupport")
                  }}</span>
                  {{ $t("faqPage.directChatSupportText") }}
                </li>
                <li>
                  <span class="font-weight-bold">{{
                    $t("faqPage.faqResources")
                  }}</span>
                  {{ $t("faqPage.faqResourcesOneText") }}
                  <p class="mt-3">
                    {{ $t("faqPage.faqResourcesTwoText") }}
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </li>
        <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-2">
              {{ $t("faqPage.myTaxRefund") }}
            </div>
            {{ $t("faqPage.myTaxRefundText") }}
          </div>
          <div class="mb-3 text-blue" @click="() => changeShow(9)">
            <span class="font-weight-bold read-more">{{
              $t("faqPage.readMore")
            }}</span>
          </div>
          <div v-if="isShow.includes(9)">
            {{ $t("faqPage.myTaxRefundOneText") }}
            {{ $t("faqPage.myTaxRefundTwoText") }}
          </div>
        </li>
        <!-- <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-2">
              {{ $t("faqPage.trackProcessing") }}
            </div>
            {{ $t("faqPage.trackProcessingText") }}
          </div>
          <p class="mb-3 text-blue">
            <span
              class="font-weight-bold read-more"
              @click="() => changeShow(10)"
              >{{ $t("faqPage.readMore") }}</span
            >
          </p>
          <div v-if="isShow.includes(10)">
            <p class="mb-3">
              {{ $t("faqPage.trackProcessingOneText") }}
              {{ $t("faqPage.trackProcessingTwoText") }}
            </p>
            <ol class="font-weight-bold ol-padding">
              <li>{{ $t("faqPage.trackProcessingOneItem") }}</li>
              <li>{{ $t("faqPage.trackProcessingTwoItem") }}</li>
              <li>{{ $t("faqPage.trackProcessingThreeItem") }}</li>
              <li>{{ $t("faqPage.trackProcessingFourItem") }}</li>
              <li>{{ $t("faqPage.trackProcessingFiveItem") }}</li>
            </ol>
            <p class="mt-3">
              {{ $t("faqPage.transparentProcessingSteps") }}
            </p>
          </div>
        </li> -->
        <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-2">
              {{ $t("faqPage.costUseFlinktax") }}
            </div>
            {{ $t("faqPage.costUseFlinktaxText") }}
          </div>
          <p class="text-blue mb-3">
            <span
              class="font-weight-bold read-more"
              @click="() => changeShow(11)"
              >{{ $t("faqPage.readMore") }}</span
            >
          </p>
          <div v-if="isShow.includes(11)">
            {{ $t("faqPage.costUseFlinktaxOneText") }}
          </div>
        </li>
        <li>
          <div class="mb-2">
            <div class="font-weight-bold mb-2">
              {{ $t("faqPage.smartPhoneFlinktax") }}
            </div>
            {{ $t("faqPage.smartPhoneFlinktaxText") }}
          </div>
          <p class="text-blue mb-3">
            <span
              class="font-weight-bold read-more"
              @click="() => changeShow(12)"
              >{{ $t("faqPage.readMore") }}</span
            >
          </p>
          <p v-if="isShow.includes(12)">
            {{ $t("faqPage.smartPhoneFlinktaxOneText") }}
            {{ $t("faqPage.smartPhoneFlinktaxTwoText") }}
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: [],
    };
  },
  methods: {
    changeShow(val) {
      const index = this.isShow.findIndex((show) => show == val);

      if (index > -1) {
        this.isShow.splice(index, 1);
      } else {
        this.isShow.push(val);
      }
    },
  },
};
</script>

<style lang="scss">
.faq {
  padding: 160px 0px;
  text-align: justify;

  ol {
    li {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      &::marker {
        font-weight: bold;
      }
    }
  }

  .ol-padding {
    li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .read-more {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>